import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { compose } from "recompose"

import { accountPropTypes } from "../form/constructor/ConstructorModel"

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM })

class PayPalContainer extends React.Component {
  onApprove = (data, actions) => {
    const { orderCompleted } = this.props

    actions.order.capture().then(orderCompleted)
  }

  createOrder = (data, actions) => {
    const {
      totalPrice, email, accounts, period
    } = this.props

    const order = actions.order.create({
      payer: {
        email_address: email
      },
      purchase_units: [{
        amount: {
          value: totalPrice
        },
        items: Array.from(accounts).map(({ login, mac, device }) => ({
          name: login || mac,
          description: device,
          quantity: "1",
          unit_amount: {
            currency_code: "EUR",
            value: "0"
          },
        })),
        custom_id: btoa(JSON.stringify(
          { price: totalPrice, period }
        )).replace(/=/g, "")
      }]
    })

    return order
  }

  render() {
    return (
      <PayPalButton
        createOrder={this.createOrder}
        onApprove={this.onApprove}
      />
    )
  }
}

PayPalContainer.defaultProps = {
  email: undefined
}
PayPalContainer.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  email: PropTypes.string,
  accounts: PropTypes.arrayOf(accountPropTypes).isRequired,
  period: PropTypes.number.isRequired,

  orderCompleted: PropTypes.func.isRequired
}

const mapStateToProps = ({ subscription }) => ({

})

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch)

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PayPalContainer)
